.App {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 16px 16px rgba(0, 0, 0, 0.2);
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff;
}

/* .App::after {
  content: "";
  position: absolute;
  top: 0;
  left: -30px;
  right: -30px;
  bottom: 0;
  width: calc(100% + 60px);
  height: 100%;
  background-color: #fff;
  z-index: -1;
} */

.App-logo-container {
  position: relative;
  width: 340px;
  height: 340px;
  pointer-events: none;
  animation-name: App-logo-spin;
  animation-duration: 160s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin: 0 40px;
}

.App-logo {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("./sun-logo-orig.png");
  z-index: 5;
}

.App-logo-pulsating {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("./sun-logo-orig.png");
  animation-name: App-logo-pulsate;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-direction: reverse;
  animation-fill-mode: both;
  animation-play-state: running;
  opacity: 0.6;
  z-index: 0;
}

.App-header {
  background-color: #fff;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.subtitle {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  color: red;
  text-transform: uppercase;
  transform: translateY(-110px);
  letter-spacing: 60px;
}

.entries {
  display: grid;
  grid-template-columns: repeat(2, 300px);
  grid-column-gap: 50px;
  margin-top: 40px;
}

.entries__item {
  width: 100%;
  background-color: #0e5b0b;
  font-size: 28px;
  font-weight: 700;
  color: #fafafa;
  text-decoration: none;
  padding: 15px 5px;
  border-radius: 4px;
}

.entries__item--blue {
  background-color: #0e3adb;
}

.entry-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.entry-list {
  display: grid;
  margin-right: auto;
  margin-left: auto;
  grid-template-columns: 450px 450px;
  grid-column-gap: 120px;
  align-content: center;
  justify-items: center;
  margin-bottom: 60px;
}

.entry-link {
  display: flex;
  width: 450px;
  height: 70px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 700;
  border-radius: 6px;
  background-color: #0e5b0b;
  text-decoration: none;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.entry-image {
  width: 100%;
  height: 320px;
  object-fit: contain;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 8px rgba(0, 0, 0, 0.12);
}

.entry-desc {
  text-align: center;
  color: blue;
  width: 1400px;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  font-family: Arial, sans-serif;
}

* {
  box-sizing: border-box;
}
